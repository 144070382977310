/*APP3——section_3*/
.S3_Top{
    width: 100%;
    height: 50%;
    /*background: url("../img3/Section_3.png") no-repeat center center;*/
    background-size: cover;
}
.S3_Top>div{
    width: 90%;
    margin: 0 auto;
    text-align: center;
    padding: 6rem;
}
.S3_Top h3{
    font-size:2.5rem;
    font-weight:bold;
    color:rgba(255,255,255,1);
    padding: 1rem;
}
.S3_Top p{
    width: 1000px;
    margin: 0 auto;
    text-align: left;
    font-size:2.5rem;
    font-weight:400;
    color:rgba(255,255,255,1);
    line-height:4.3rem;
}
.S3_box{
    width: 100%;
    height: 70%;
    margin: 0 auto;
    background: rgba(238,241,247,1);
}
.S3_box_top,.S3_box_bot{
    width: 80%;
    height: 30%;
    margin: 0 auto;
}
.S3_box_top{
    padding-top: 2rem;
}
.S3_box_top h3{
    font-size: 2.5rem;
}
.S3_box_top p{
    font-size: 1.5rem;
    width: 90%;
}
.S3_box_bot{
    height: 70%;
    padding: 4.25rem 0 5rem;
}
.S3_box_bot ul{
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.S3_box_bot ul li{
    width: 32.5%;
    height: 100%;
    margin-bottom: 20px;
    float: left;
    text-align: center;
    border: 1px solid rgba(238,241,247,1);
    background:rgba(255,255,255,1);
    position: relative;
}
.S3_box_bot ul li>img{
    width: 100%;
}
.S3_li_img{
    width: 7.5rem;
    height: 7.5rem;
    margin:0 auto;
    background-repeat: no-repeat;
    background-position:center center;
    background-size: cover;
}
.S3_box_bot p{
    font-size:1.1rem;
    font-weight:400;
    text-indent: 0.4rem;
    color:rgba(102,102,102,1);

    height:3.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background:rgba(255,255,255,0.9);
    position: absolute;
    bottom: 10px;
    left: 10px;
    right: 10px;
}
.S3_box_bot p span{
    position: absolute;
    text-align: left;
    width: 1.5rem;
    height: 1.5rem;
    font-size: 1rem;
    left: 25%;
    top: 50%;
    transform: translateY(-50%);
    color: rgba(220,55,48,1);
    border: 1px solid rgba(220,55,48,1);
    border-radius: 1.5rem;
    transition: all 0.5s cubic-bezier(0.25,0.1,0.25,1);
}

.S3_box_bot ul li:hover ,
.S3_box_bot ul li:hover  p{
    background:rgba(220,55,48,1);
    color:rgba(255,255,255,1);
}
.S3_box_bot ul li:hover span{
    width: 50%;
    left: 25%;
    color: rgba(255,255,255,1);
    border: 1px solid rgba(255,255,255,1);
    border-radius: 20px;
    text-indent: 0.6rem;
}
.S3_li_T1{
    background-image: url("../images/img3/YuanCL.png");
}
.S3_box_bot ul li:hover .S3_li_T1{
    background-image: url("../images/img3/YuanCL-bai.png");
}
.S3_li_T2{
    background-image: url("../images/img3/GongYYF.png");
}
.S3_box_bot ul li:hover .S3_li_T2{
    background-image: url("../images/img3/GongYYF-bai.png");
}
.S3_li_T3{
    background-image: url("../images/img3/DianXYF.png");
}
.S3_box_bot ul li:hover .S3_li_T3{
    background-image: url("../images/img3/DianXYF-bai.png");
}
.S3_li_T4{
    background-image: url("../images/img3/MZKM.png");
}
.S3_box_bot ul li:hover .S3_li_T4{
    background-image: url("../images/img3/MCKM-bai.png");
}
.S3_li_T5{
    background-image: url("../images/img3/CPZZ.png");
}
.S3_box_bot ul li:hover .S3_li_T5{
    background-image: url("../images/img3/CPZZ-bai.png");
}
.S3_li_T6{
    background-image: url("../images/img3/WCCH.png");
}
.S3_box_bot ul li:hover .S3_li_T6{
    background-image: url("../images/img3/WCCH-bai.png");
}

@media (max-width:1380px) {
    .S3_box_bot p span{
        left: 20%;
    }
}
