.App2{
    width: 100%;
    height: 100%;
}
a{
    color: white;
}
.A2_box{
    width: 100%;
    height: 100%;
}
.A2_top{
    width: 100%;
    height: 75%;
}
.A2_bot{
    width: 100%;
    height: 25%;
}
html,body{overflow:hidden;}

.section-wrap .section{ position:relative; width:100%; height:100%; background-position:center center; background-repeat:no-repeat;}
.put-section-0{ transform:translateY(0);-webkit-transform:translateY(0);}
.put-section-1{ transform:translateY(-100%);-webkit-transform:translateY(-100%);}
.put-section-2{ transform:translateY(-200%);-webkit-transform:translateY(-200%);}
.put-section-3{ transform:translateY(-300%);-webkit-transform:translateY(-300%);}
.put-section-4{ transform:translateY(-400%);-webkit-transform:translateY(-400%);}
.put-section-5{ transform:translateY(-500%);-webkit-transform:translateY(-500%);}
.put-section-6 {
    transform: translateY(-545%);
    -webkit-transform: translateY(-545%);
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


