#xing{
    width: 100%;
    height: 100vh;
    /*background: url("../img/nav-4.png") no-repeat center center;*/
    background-size: cover;
    transition: all 1s;
}
.load_img{

}
#xing .hd{
    width: 80vw;
    text-align: left;
    /*margin: auto;*/
    padding-top: 20vh;
    color: rgba(255,255,255,1);
    opacity: 0;

    position: relative;
    left: 2.6rem;
    transition: all 1s;
}
#xing .hd h3{
    font-size: 3.7rem;
    display: inline-block;
    font-weight:bold;
    position: relative;
}

#xing .hd h3::before{
    width: 7.5rem;
    height: 0.4rem;
    background:rgba(197,197,197,1);
    display: block;
    position: absolute;
    left: 0rem;
    bottom: -1.5rem;
    content:' ';
}
#xing .hd p{
    margin-top: 2.6rem;
    line-height:3rem;
    font-size: 1.4rem;
    width: 50%;
    font-weight:400;
    color:rgba(87,87,87,1);
    text-shadow:0px 3px 5px rgba(0, 0, 0, 0.1);

}
