#zx {
    width: 100%;
    height: 100%;
    color: rgba(76,76,76,1);
    background: rgba(240,240,240,1);
}

#zx .zx_box{
    height: 100%;
    background-size: auto;
    background-position: top left;
    /*background: url("../img/latest-information.png") no-repeat top left;*/
}
#zx .box_head{
    width: 80%;
    text-align: left;
    height: 25%;
    margin: 0 auto;
}
#zx .box_content{
    height: 55%;
}
#zx .box_footer{
    height: 20%;
    text-align: center;
    display: flex;
    justify-content:center;
    align-items:Center;
}
#zx .box_footer a{
    font-size: 1.5rem;
    font-weight: 400;
    color: rgba(205,27,18,1);
    padding: 0.5rem 1rem;
    text-decoration: none;
    margin:0 auto;
    /*margin-top: 2rem;*/
    width: 10rem;
    border: 1px solid rgba(205,27,18,1);
    display: block;
}
#zx .box_footer a:hover{
    background: url("../images/img/Background.png");
    color: rgba(255,255,255,1);
}

#zx .box_head div{
    padding-top: 3%;
    margin-left: -10%;
    opacity: 0.1;
    transition: all 1s;
}

#zx .box_head h3{
    font-size: 2.5rem;
    font-weight:bold;
    color: rgba(76,76,76,1);
}
#zx .box_head p{
    font-size: 2.5rem;
    font-weight:bold;
    color:rgba(102,102,102,1);
    opacity:0.2;
    text-transform: uppercase;
}

#zx .container{
    margin-left: 10%;
    width: 80%;
}

#zx .col_1{
    position: relative;
    margin-left: -15px;
}
#zx .p_1{
    position: absolute;
    font-size:1.5rem;
    top: -3rem;
    font-weight:400;
    color:rgba(51,51,51,1);
}
#zx .card,#zx .container,#zx .row,#zx .row>div,#zx .col_1{
    border-radius: 0;
    border: 0;
    height: 100%;
}

#zx .col_1 .c1_img{
    width: 100%;
    height: 60%;
    overflow: hidden;
}
#zx .col_1 .c1_img img{
    width: 100%;
    transform: scale(1.5);
    transition: all 1s;
}
#zx .card-body{
    width: 100%;
    height: 40%;
    padding: 2rem 1.25rem 1.25rem;
    position: relative;
}

#zx .card-body::after{
    content: '';
    position: absolute;
    height: 3px;
    width: 0%;
    background: red;
    bottom: 0;
    left: 0;
    transition: all 1s;
}
#zx .card:hover .card-body::after{
    width: 100%;
}
#zx .content{
    width: 70%;
    text-align: left;
    float: left;
}
#zx .content .card-title{
    line-height: 1.7rem;
    -webkit-box-orient:vertical;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}
#zx .date{
    width: 20%;
    float: right;
    font-size: 1rem;
}
#zx .date h3{
    font-size: 3rem;
    font-weight:bold;
    line-height: 2.75rem;
    color:rgba(153,153,153,1);
    text-align: center;
}

#zx .date p{
    font-size: 1rem;
    font-weight:400;
    line-height: 3rem;
    color:rgba(153,153,153,1);
    text-align: center;
}

#zx .date span{
    display: block;
    margin: 1rem auto 0;
    width: 2.1rem;
    height: 2rem;
    background: url("../images/img/you-hover.png") no-repeat center;
    background-size: 1.5rem;
}
#zx .date span:hover,.left:hover span{
    background: url("../images/img/next.png") no-repeat center;
}

#zx .card-title{
    font-size: 1.1rem;
    font-weight:bold;
    color:rgba(102,102,102,1);
}
#zx .card-text{
    font-size: 0.75rem;
    line-height: 1.4rem;
    text-align: left;
    margin-top: 1rem;
    height:3.8rem;
    color: #666666;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}

#zx .col_2{
    width: 100%;
    height: 100%;
    display: flex;
    padding-right: 1rem;
    flex-wrap: wrap;
    align-content: space-between;
}
#zx .col_2>a{
    width: 100%;
    height: 49%;
}
#zx .col_2 .c2_1{
    font-size: 16px;
    width: 100%;
    height: 100%;
    background: white;
}
#zx .col_2 .c2_1:nth-child(2){
    /*margin-top: 1rem;*/
    padding-top: -1rem;
}
#zx .col_2 .c2_1 .box1{
    height: 35%;
    padding: 0.5rem;
    position: relative;
}

#zx .col_2 .c2_1 .box1::after,.col3 .box1::after{
    content: '';
    display: block;
    position: absolute;
    bottom: -0.5rem;
    left: 5%;
    width:90%;
    height:1px;
    background:rgba(153,153,153,1);
    opacity:0.2;
}
#zx .img{
    height: 65%;
    width: 100%;
    padding: 1.5rem;
    overflow: hidden;
}
#zx .img>div{
    width: 100%;
    height: 100%;
}

#zx .img img{
    width: 100%;
    height: 100%;
    /*transform: scale(1.5);*/
    transition: all 1s;
}
#zx .img:hover img{
    transform: scale(2);
}
.right,.center,.left{
    float: left;
    height: 100%;
}
.right{
    display: flex;
    width: 20%;
    padding-top: 0.5rem;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
.right h3{
    width: 100%;
    font-size:2.8rem;
    font-weight:bold;
    color:rgba(153,153,153,1);
}
.right p{
    width: 100%;
    font-size:0.8rem;
    margin: 0;
    font-weight:400;
    color:rgba(153,153,153,1);
}
.center{
    width: 65%;
    text-align: left;
    overflow:hidden;
    position:relative;
    display:table-cell;
    vertical-align:middle;
}
.center p{
    margin-top: 1.5rem;
    font-size:1.1rem;
    font-weight:bold;
    color:rgba(102,102,102,1);
}
.left{
    width: 10%;
}
.left span{
    display: inline-block;
    width: 1.5rem;
    height: 1rem;
    margin-top: 2.4rem;
    background: url("../images/img/you-hover.png") no-repeat center 2.5rem;
    background-size: 1.5rem;
    opacity: 0.5;
}
.left:hover span{
    background: url("../images/img/next.png") no-repeat center 2.5rem;
}
.img img{
    width: 90%;
}
.col3{
    width: 100%;
    height: 100%;
    background: white;
}
.col3 .c2_2{
    height: 100%;
}
.col3 .box1{
    position: relative;
    overflow: hidden;
    height: 25%;
}

.col3 .right ,.left{
    display:table;
}
.col3 .right div ,.center p,.left span{
    vertical-align:middle;
    display:table-cell;
}
.col3 .center p{
    position: absolute;
    margin: 0;
    top: 50%;
    transform: translateY(-50%);
    color:rgba(102,102,102,1);

    -webkit-box-orient:vertical;
    display: -webkit-box;
    display: -moz-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
}
.col3 .left span{
    background: url("../images/img/you-hover.png") no-repeat center ;
    background-size: 1.5rem;
}
.col3 .left:hover span{
    background: url("../images/img/next.png") no-repeat center;
}
.c2_1 .center p{
    -webkit-box-orient:vertical;
    display: -webkit-box;
    display: -moz-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
}
.col3 .box1::after{
    top: 0rem;
}
.col3 .box1:nth-child(0)::after{
    top: -1rem;
}

