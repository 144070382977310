
.JiShuFW_box{
    width: 80%;
    margin: 0 auto;
    text-align: center;
}

.JiShuFW_bot{
    padding: 6rem 0 9rem;
}
.JiShuFW_bot ul{
    display: flex;
    justify-content: center;
}
.JiShuFW_bot li{
    width: 50%;
    min-height: 15rem;
    padding: 3.75rem;
    text-align: left;
    display: inline-block;
    position: relative;
    border-top: 2px solid rgba(226,39,39,1);
    background:rgba(255,255,255,1);

}
.JiShuFW_bot li:hover{
    box-shadow:0px 6px 8px 0px rgba(102,102,102,0.3);
}
.JiShuFW_bot li img{
    display: inline-block;
    position: absolute;
    top: 0;
    left: 10%;
    z-index: 10;
    transform: translateY(-50%);
}
.JiShuFW_bot li::after{
    content: '';
    width: 100px;
    height: 2px;
    position: absolute;
    left: 10%;
    top: -2px;
    z-index: 1;
    background: rgba(255,255,255,1);
}
.JiShuFW_bot li::before{
    content: '';
    width: 10px;
    height: 10px;
    position: absolute;
    left: 200px;
    top: 30px;
    z-index: 1;
    background: rgba(226,39,39,1);
}
.JiShuFW_bot li:nth-child(1){
    margin-right: 5rem;
}

.JiShuFW_bot li p{
    font-size:1.5rem;
    line-height: 2.5rem;
    font-weight:400;
    color:rgba(102,102,102,1);
}
.JiShuFW_bot .sanJ{
    width: 40px;
    height: 40px;
    position: absolute;
    bottom: 7px;
    right: 9px;
}

.JiShuFW_ban{
    height: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.JiShuFW_ban p{
    font-size:2.5rem;
    font-weight:400;
    color:rgba(255,255,255,1);
}