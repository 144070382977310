#Li{
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
}
#Li .Limg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    /*background: url("../img/nav-2.png") no-repeat center center;*/
    background-size: cover;
}
#Li .Lu{
    width: 100%;
    position: absolute;
    color: rgba(76,76,76,1);
    text-align: center;
    top: 10%;
    left: 50%;
    transform: translate(-50%,-50%);
    transition: all 1s;
}
#Li .Lp{
    position: absolute;
    background: rgba(255,255,255,1);
    color: rgba(76,76,76,1);
    width: 100vw;
    height: 20vh;
    bottom: 0vh;
    left: 0vw;
}
#Li .Lp p{
    width: 70%;
    margin: 15rem auto 8rem;
    opacity: 0.1;
    line-height: 2rem;
    text-align: left;
    font-size: 1.2rem;
    font-weight:400;
    color:rgba(76,76,76,1);
    transition: all 1s;
}
#Li .Lu h3{
    font-size: 3.7rem;
    line-height: 6rem;
    display: block;
    font-weight:bold;
    color:#4C4C4C;
    position: relative;
}
#Li .Lu p{
    font-size: 3.7rem;
    line-height: 6rem;
    display: inline-block;
    font-weight:bold;
    color:#4C4C4C;
    text-align: center;
}
.Lu_ul{
    width: 100%;
    height: 60%;
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: 0;
}
.Lu_ul li{
    line-height: 60vh;
    font-size:34px;
    text-align: center;
    font-weight:bold;
    color:rgba(255,255,255,1);
    width: 20%;
}