/*APP3——section_4*/
#Section_4{
    width: 100%;
    position: relative;
}
.S4_router{
    width: 100%;
    position: absolute;
    box-shadow: 0 2px 8px 5px rgba(0,0,0,0.1);
    top: 0;
    z-index: 1000;
    background:rgba(238,241,246,0.3);
}

.S4_router>ul{
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
}
.S4_router>ul li{

    float: left;
    width: 33%;
    margin-right: 2px;
    background-color: white;
    line-height: 1.6rem;
    padding: 1rem 0;
    font-size:1.2rem;
    cursor: pointer;
    text-align: center;
}

.S4_router>ul li:first-child{
    /*border-left: 1px solid rgba(238,241,247,0);*/
}
#Section_4 a:not([href]):not([tabindex]){
    display: flex;
    justify-content: center;
    align-items: center;
    color:rgba(102,102,102,1);
}
.S4_router>ul a{
    font-weight:400;
    color:rgba(102,102,102,1);
    width: 100%;
    height: 100%;
    display: block;
}

.S4_router>ul li:hover{
    background:rgba(220,55,48,1);
    color: rgba(255,255,255,1)!important;
}
.S4_router>ul li:hover a{
    color: rgba(255,255,255,1)!important;
}
.S4_router>ul li:hover a:not([href]):not([tabindex]){
    color: rgba(255,255,255,1)!important;
}
.red {
    background: rgba(220,55,48,1)!important;
    color: rgba(255,255,255,1)!important;
}
.red a{
    color: rgba(255,255,255,1)!important;
}
.block{
    display: block;
}
.none{
    display: none;
}
