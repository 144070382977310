#Select{
    width: 100%;
    height: 100%;
    text-align: center;
    font-size:1.5rem;
    font-weight:400;
    color:rgba(118,118,118,1);
}
.Select_top,.Select_bot{
    background-size: cover;
}
.Select_top{
    height: 70%;
}
.Select_bot{
    height: 30%;
}
.sel_content{
    width: 80%;
    height: 100%;
    margin: 0 auto;
}
.sel_content h3{
    height: 40%;
    display: flex;
    justify-content: center;
    align-items: Center;
    font-size:3.75rem;
    font-weight:bold;
    color:rgba(118,118,118,1);
    line-height:3.75rem;
}
.sel_content>p{
    width: 70%;
    margin: 0 auto;
    text-align: left;
}
.sel_content>ul{
    width: 95%;
    margin: 5rem auto 0;
    display: flex;
    justify-content: center;
    align-items: Center;
    overflow: hidden;
}
.sel_content>ul li{
    text-align: center;
    padding: 0 2rem;
    line-height: 4rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.sel_content>ul li p{
    font-size: 1.3rem;
    width: 100%;
}
.Select_bot ul{
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: Center;
    margin-left: 20% ;
    overflow: hidden;
}
.Select_bot ul li{
    width: 100%;
    float: left;
    text-align: center;
}
.Select_bot ul li span{
    color:rgba(228,20,20,1);
    margin-right: 1rem;
}
.Select_bot ul li p{
    font-size: 2.4rem;
    width: 100%;
    line-height:3.75rem;
    color:rgba(255,255,255,1);
    text-shadow:1px 1px 4px rgba(0, 0, 0, 0.46);
}