#S4_Li{
    width: 100%;
    height: 100%;
    background: rgba(238,241,247,1);
}
.S4_Li_content{
    background: rgba(255,255,255,1);
    margin-bottom: 5rem;

}
.S4_Li_Curtain{
    width: 100%;
    height: 800px;
    /*background: url("../img3/Libanner2.png") no-repeat center center;*/
    background-size: cover;
    position: relative;
}
.S4_Li_Curtain>div{
    width: 80%;
    margin: auto;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.S4_Li_Curtain>div h3{
    padding: 2rem 0;
    font-size:2.5rem;
    font-weight:bold;
    color:rgba(255,255,255,1);
    text-shadow:0px 3px 5px rgba(43,43,43,0.15);
}
.S4_Li_Curtain>div p{
    font-size:3.75rem;
    font-weight:400;
    color:rgba(255,255,255,1);
}

.S4_Li_box{
    width: 80%;
    margin: 0 auto;
    padding: 2rem 0 7rem;
}
.S4_Li_top{
    width: 100%;

}
.S4_Li_top_title{
    text-align: left;
    width: 100%;
    display: inline-block;
}
.S4_Li_top h3{
   text-align: left;
    font-size: 2rem;
}
.S4_Li_top p{
    width: 100%;
    text-align: left;
    font-size: 1.5rem;
}
.S4_Li_TitleImg{
    width: 40%;
    height: 12rem;
    background: url("../images/img3/title_Li.png") no-repeat center center;
    background-size: cover;
    display: inline-block;
}

.S4_Li_bot {
    width: 100%;
    overflow: hidden;
    padding: 4rem 0 0rem;
}
.S4_Li_bot li{
    width: 25%;
    height: 20rem;
    float: left;
    padding: 3rem 2rem;
    text-align: center;
    position: relative;
}
.S4_Li_bot li::after{
    content: '';
    position: absolute;
    right: 0;
    top: 0%;
    width: 2px;
    height: 100%;
    background: rgba(238,241,247,1);
}
.S4_Li_bot li:nth-child(4n)::after{
    height: 0px;
}
.S4_Li_bot li:nth-child(n+5){
    border-top: 2px solid rgba(238,241,247,1);
}
.S4_Li_bot li h3{
    text-transform:uppercase;
    display: inline-block;
    padding: 0.5rem 1rem;
    font-size:1rem;
    font-weight:400;
    color:rgba(220,55,48,1);
    border:1px solid rgba(220,55,48,1);
}
.S4_Li_bot li p{
    font-size:0.9rem;
    text-align: left;
    font-weight:400;
    color:rgba(102,102,102,1);
    line-height:1.6rem;
    margin-top: 2rem ;
}
.S4_Li_bot li:hover ,
.S4_Li_bot li:hover p,
.S4_Li_bot li:hover::after{
    background:rgba(220,55,48,1);
    color:rgba(255,255,255,1);
}
.S4_Li_bot li:hover h3{
    color:rgba(255,255,255,1);
    border:1px solid rgba(255,255,255,1);
}