/*App5_Global */
.Global_banner{
    width: 100%;
    height: 100vh;
    position: relative;
}
.Global_banner .Banner_title{
    width: 100%;
    text-align: left;
    padding-left: 10%;
}
.Global_banner .Banner_XW_title{
    width: 25%;
}
.Global_banner .Banner_title h3{
    width: 100%;
    margin-bottom: 2.5rem;
    padding: 0;
    font-size:3.75rem;
    font-weight:bold;
    position: relative;
    color:rgba(254,254,254,1);
}
.Global_banner .Banner_title h3::after{
    content: '';
    width:8rem;
    height:4px;
    background:rgba(220,55,48,1);
    position: absolute;
    bottom: -1.5rem;
    left: 0;
}
.Global_banner .Banner_title p{
    padding: 0;
}
.Global_banner .Banner_title p:nth-child(1){
    display: block;
    margin-bottom: 2rem;
    font-size:2.5rem;
    font-weight:400;
    color:rgba(254,254,254,1);
}
.Global_banner .Banner_title p:nth-child(2){
    font-size:1.5rem;
    font-weight:400;
    color:rgba(254,254,254,1);
}
