/*APP4——Cp-select*/
.row{
    margin: 0;
    width: 100%;
}
.row>div{
    padding: 0;
}
.row>div:first-child {
    padding-right: 1rem;
}
.Cp_Select>ul li{
    width: 20.5%;
    /*border-right: 1px solid rgba(238,241,247,1);*/

}
.Cp_Select_content .row>div:first-child{
    padding-right: 0rem;
}
.Cp_Select_content .row>div:last-child{
    padding-left: 1rem;
}
.Cp_top_banner{
    height: 42.5rem;
}
.Cp_Select_content{
    width: 100%;
    background:rgba(238,241,247,1);
}
.Cp_Select_box{
    width: 100%;
}
.Cp_Select_box:last-child{
    padding-bottom: 5rem ;
}
.Cp_Select_title{
    width: 80%;
    text-align: left;
    margin: auto;
    padding: 5rem 0 2rem;
}
.Cp_Select_title h3{
    text-transform: uppercase;
    font-size:2.5rem;
    font-weight:bold;
    color:rgba(220,55,48,1);
    line-height: 5rem;
    position: relative;
}
.Cp_Select_title h3::after{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    width: 5rem;
    background: rgba(220,55,48,1);
}
.Cp_Select_title p{
    width: 100%;
    padding-top: 1rem;
}

.Cp_Select_modular{
    width: 80%;
    min-height: 36rem;
    margin: 2rem auto;
}
.Cp_Select_modular .container{
    margin: 0;
    padding: 0;
}
.Cp_Select_modular>div{
    width: 100%;
    height: 100%;
}
.Cp_Select_modular_left{
    width: 100%;
    position: relative;
    height: 35rem;
    z-index: 100;
    transition: all 0.5s;
}
.Cp_Select_content .col-lg-8{
    overflow: hidden;
}
.Cp_Select_content .col-lg-8:hover .Cp_Select_modular_left{
    transform: scale(1.1);
}
@keyframes  douD {
    0%{}
    100%{}
}
.Cp_Select_modular_left>div:last-child{
    position: absolute;
    z-index: 100;
    color:rgba(102,102,102,1);
    top: 3rem;
    left: 3rem;
}
.Cp_Select_modular_left>div:last-child h3{
    font-size:2rem;
    font-weight:400;
    line-height:2rem;
    margin-bottom: 2rem;
}
.Cp_Select_modular_left>div:last-child ul{
    padding-left: 1.5rem;
    width: 100%;
}
.Cp_Select_w100{
    width: 100%!important;
}
.Cp_Select_modular_left>div:last-child ul li{
    font-size:1.2rem;
    font-weight:400;
    position: relative;
}
.Cp_Select_modular_left>div:last-child ul li::after{
    content: '';
    position: absolute;
    left: -1rem;
    top: 0.6rem;
    width:6px;
    height:6px;
    background:rgba(153,153,153,1);
    border-radius:50%;
}
.Cp_Select_modular_right{
    height: 35rem;
}
.Cp_Select_modular_right_top{
    height: 50%;
    padding-bottom: 1rem;
    display: flex;
    justify-content: space-between;
}
.Cp_Select_modular_right_top>div{
    width: 48%;
    height: 100%;
    overflow: hidden;
}
.Cp_Select_modular_right_top>div>img{
    width: 100%;
    height: 100%;
    transition: all 0.5s;
}
.Cp_Select_modular_right_top>div:hover img{
    transform: scale(1.2);
}
.Cp_Select_modular_right_top img{
    width: 48%;
    height: 100%;
}
.Cp_Select_modular_right_top img:nth-child(1){
    /*margin-right: 0.5rem;*/
}
.Cp_Select_modular_right_bottom{
    background:rgba(255,255,255,1);
    height: 50%;
}
.Cp_Select_modular_right_bottom>div{
    padding: 2rem ;
}
.Cp_Select_modular_right_bottom h3{
    font-size:1.2rem;
    font-weight:400;
    color:rgba(51,51,51,1);
    line-height:3rem;
}
.Cp_Select_modular_right_bottom p{
    font-size:1rem;
    font-weight:400;
    color:rgba(102,102,102,1);
    line-height:1.8rem;
}

.Cp_Select_modular_right_bottom>div div{
    user-select: none;
    font-size:1.2rem;
    font-weight:400;
    color:rgba(102,102,102,1);
    line-height:2.8rem;
    width: 10rem;
    height: 2.8rem;
    position: absolute;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    border: 1px solid rgba(102,102,102,1);
}
.Cp_Select_modular_right_bottom>div div:hover{
    color:rgba(255,255,255,1);
    border:0px!important;
    background: rgba(220,55,48,1);
    cursor: pointer;

}

.Cp_Select_modular_tab {
    width: 100%;
    margin: 3rem 0 0;
    background:rgba(255,255,255,1);
    height: 0rem;
    overflow: hidden;
    transition: all 0.3s;
}
.Cp_Select_modular_tab table{
    width: 80%;
    text-align: center;
    margin: 3rem auto;
}

.Cp_Select_modular_tab .table td, .Cp_Select_modular_tab.table th{
    vertical-align: inherit;
    padding: 1%;
    /*border-top: 0px solid #dee2e6;*/
    border: 1px solid #dee2e6;
}
.Cp_Select_modular_tab thead td{
    background:rgba(220,55,48,1);
    font-size:1.5rem;
    font-weight:400;
    color:rgba(255,255,255,1);
    padding: 0;
}
@media (min-width: 1300px) {
    .Cp_Select_modular .container{
        min-width: 100%!important;
    }
}