/*App7 - Contact_banner*/
.Contact_banner{
    width: 100%;
    height: 100vh;
    position: relative;
}
.Contact_title{
    padding: 0;
    margin: 0;
    width: auto;
    height: auto;
    position: absolute;
    left: 13%;
    top: 40%;
    text-align: left;
}
.Contact_title h3{
    padding-bottom: 2rem;
    font-size:2.5rem;
    font-weight:bold;
    position: relative;
    color:rgba(254,254,254,1);
}
.Contact_title h3::after{
    content: '';
    width: 8rem;
    height: 4px;
    background: rgba(220,55,48,1);
    position: absolute;
    bottom: 1.1rem;
    left: 0;
}
.Contact_title p{
    line-height: 2.5rem;
    font-size:1.5rem;
    font-weight:400;
    color:rgba(254,254,254,1);
}


