#scrollBar{
    height: 100vh;
    width: 100%;
    position: relative;
}

#scrollBar .sou{
    display: block;
    position: absolute;
    top: 3rem;
    right: 3.75rem;
    z-index: 100;
}
#scrollBar .form{
    display: inline-block;
    border-bottom: 1px solid rgba(255,255,255,1);
}
#scrollBar .serch{
    padding-top: 1.1rem;
    padding-right: 1.1rem;
    position: relative;
    top: 0.1rem;
    /*background: url("../img/serch.png") no-repeat center center;*/
    display: inline-block;
    width: 1rem;
    height: 1rem;
}

#scrollBar .form input{
    font-size: 1rem;
    color: white;
    border: 0;
    outline:none;
    text-indent:0.5rem;
    background: rgba(248, 244, 255, 0);
    width: 0px;
}
#scrollBar .serch:focus + .form input{
    animation: maxWidth 1s forwards;
}
#scrollBar .form input:focus{
    width: 10rem
}
@keyframes maxWidth {
    from {width: 0px}
    to {width: 10rem}
}

#scrollBar .form input::-webkit-input-placeholder{
    color: white;
}
#scrollBar .sou span{
    color: white;
    font-size: 1.2rem;
}
#scrollBar .sou a{
    font-size: 1.2rem;
    list-style: none;
    color: white;
}

#scrollBar .banner1{
    width: 100vw;
    height: 100%;
    /*background: url("../images/img/banner1.png") no-repeat center center;*/
    background-size: cover;
}
#scrollBar .banner2,
#scrollBar .banner3{
    width: 100vw;
    height: 100%;
    /*background: url("../img/banner2_1.jpg") no-repeat center center;*/
    background-size: cover;
}
/*#scrollBar .banner2{
    background: url("../images/img/banner2_1.png") no-repeat center center
}
#scrollBar .banner3{
    background: url("../images/img/banner-3.png") no-repeat center center
}*/
.zx_list_img{
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}
#scrollBar .carousel ,#scrollBar .carousel-inner,#scrollBar .carousel-item{
    height: 100vh;
}
#scrollBar .carousel-control-prev,#scrollBar .carousel-control-next{
    width: 5rem;
    height: 5rem;
    cursor: pointer;
    bottom: 0;
    top: 90vh;
    z-index: 100;
    display: none;
}
#scrollBar .carousel-control-prev{
    left: 12vw;
    cursor: pointer;
    transition: all 0.5s;
}

#scrollBar .carousel-control-prev::after{
    content: ' ';
    cursor: pointer;
    position: absolute;
    top: 2.4rem;
    left: 2.3rem;
    width: 0rem;
    height: 2px;
    background: #666666;
    transition: all 0.3s linear;
}

#scrollBar .carousel-control-next{
    left: 16vw;
    cursor: pointer;
    transition: all 0.5s;
}

#scrollBar .carousel-control-next::after{
    content: ' ';
    cursor: pointer;
    position: absolute;
    top: 2.4rem;
    left: 3rem;
    width: 0rem;
    height: 2px;
    background: #666666;
    transition: all 0.3s linear;
}


#scrollBar .carousel-control-prev-icon{
    width: 1.3rem;
    background-image: url("../images/img/zuo1.png");
}
#scrollBar .carousel-control-next-icon{
    width: 1.3rem;
    background-image: url("../images/img/you3.png");
}

#scrollBar .carousel-control-prev:hover{
    left: 10vw;
}
#scrollBar .carousel-control-prev:hover::after{
    width: 2rem;
}
#scrollBar .carousel-control-next:hover{
    left: 18vw;
}
#scrollBar .carousel-control-next:hover::after{
    width: 2rem;
    left: 0.65rem;
}



#scrollBar .mouses{
    position: absolute;
    bottom: 5vh;
    left:48vw;
    z-index: 101;
    animation: mouset 0.5s linear infinite alternate;
}

#scrollBar .mouses a{
    display: block;
    width: 2rem;
    height: 2rem;
    background: url("../images/img/shubiao-icon.png") no-repeat center center;
}

@keyframes mouset {
    from{
        bottom:5vh;
    }
    to{
        bottom: 6vh;
    }
}

#scrollBar .tag1{
    position: absolute;
    bottom: 0.2rem;
    height: 10vh;
    left: 67%;
    text-align: left;
    line-height: 1rem;
    color: #333333;
    z-index: 100;
}
#scrollBar .tag1 h3{
    font-size: 2rem;
    line-height: 3rem;
    font-weight:bold;
    color:rgba(208,18,27,1);
}
#scrollBar .tag1 p{
    font-size: 1rem;
    line-height: 2rem;
}

#scrollBar .carousel-caption{
    position: static;
}
#scrollBar .zl{
    position: absolute;
    top:10%;
    width: 100%;
    margin: 0 auto;
}
#scrollBar .zl h3{
    font-size: 3rem;
    line-height: 5rem;
}
#scrollBar .zl p{
    font-size: 2rem;
    color: rgba(105,105,110,1);
}
#scrollBar .cx{
    position: absolute;
    top: 50%;
    right: 10vw;
    text-align: right;
}
#scrollBar .cx h3{
    font-size: 2.5rem;
    font-weight: 600;
    position: relative;
    margin-bottom: 3rem;
}
#scrollBar .cx h3::before{
    width: 8rem;
    height: 5px;
    display: block;
    position: absolute;
    bottom: -1.5rem;
    right: 0rem;
    background: red;
    content:' ';
}
#scrollBar .cx p{
    font-size: 1.5rem;
    margin-bottom: 0px;
}

#scrollBar .carousel-indicators{
    height: 12vh;
    width: 80%;
    background: rgba(255,255,255,1);
    margin: 0 auto;
    bottom: 0;
}
#scrollBar .carousel-indicators li{
    background:rgba(204,204,204,1);
    border-radius:50%;
    width: 1rem;
    height: 1rem;

    position: absolute;
    top: 50%;
    left: 5%;
    transform: translateY(-50%);
}

#scrollBar .carousel-indicators .active{
    /*display: none;*/
    background:rgba(222,33,40,1);
}