#Cp_banner{
    width: 100%;
    height: 100%;
    background: #666666;
    position: relative;
}
.Cp_banner_title{
    position: absolute;
    padding-top: 0;
    top: 50%;
    /*left: 50%;*/
    transform: translate(-50%);
    width: 100%;
    height: auto;
    background-attachment: fixed;
}
.Cp_banner_title p{
    width: 100%;
    background:url("../img4/bg.png") no-repeat center center;
}