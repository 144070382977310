/*APP4——Cp_CpAPP*/
#Cp_CpAPP{
    width: 100%;
    height: 100%;
}
#Cp_CpAPP .S3_box{
    height: 100%;
    padding-bottom: 6rem;
}

#Cp_CpAPP .S3_box_top{
    padding: 2rem 0 0;
    height: auto;
}

#Cp_CpAPP .S3_box_bot{
    height: 80%;
}

.Cp_CpAPP_h3{
    padding-top: 3%;
    text-align: center;
}
#Cp_CpAPP .S3_box_bot{
     padding: 4rem 0 3rem;
}

#Cp_CpAPP .S3_box_bot ul li{
    height: 50%;
    overflow: hidden;
    padding-top: 2.5rem;
    /*padding: 3rem 5rem;*/
}

#Cp_CpAPP .S1_top p{
    width: 70%;
    text-align: center;
    margin: 0 auto;
}

#Cp_CpAPP .S3_box_bot p span{
    position: absolute;
    text-align: center;
    width: 100%;
    height: 1.5rem;
    font-size: 1rem;
    left: 0%;
    top: 150%;
    color:rgba(255,255,255,1);
    border: 0px solid rgba(255,255,255,1);
    transition: all 0.2s;
}

#Cp_CpAPP .S3_box_bot ul li:hover span{
    text-transform: capitalize;
    width:100%;
}

.Cp_CpAPP_bot p{
    font-size: 1.2rem;
    text-transform: uppercase;
    position: relative;
    width: 70%;
    margin-top: 10%;
    transition: all 0.2s;
    left: 15%;
}

.Cp_CpAPP_bot p::after{
    content: "";
    position: absolute;
    top: -20%;
    left: 25%;
    width: 50%;
    height: 2px;
    background:rgba(220,55,48,1);
    transition: all 0.2s;
}
#Cp_CpAPP .S3_box_bot ul li:hover p{
    margin-top: 0%;
}
#Cp_CpAPP .S3_box_bot ul li:hover p::after{
    top: 110%;
    background:rgba(255,255,255,1);
}

.S4_li_T1{
    background-image: url("../img4/Cp_App/dianxin-h.png");
}
.S3_box_bot ul li:hover .S4_li_T1{
    background-image: url("../img4/Cp_App/dianxin-b.png");
}
.S4_li_T2{
    background-image: url("../img4/Cp_App/mozu-h.png");
}
.S3_box_bot ul li:hover .S4_li_T2{
    background-image: url("../img4/Cp_App/mozu-b.png");
}
.S4_li_T3{
    background-image: url("../img4/Cp_App/daindonggongju-h.png");
}
.S3_box_bot ul li:hover .S4_li_T3{
    background-image: url("../img4/Cp_App/daindonggongju-b.png");
}
.S4_li_T4{
    background-image: url("../img4/Cp_App/qiansuantidai-h.png");
}
.S3_box_bot ul li:hover .S4_li_T4{
    background-image: url("../img4/Cp_App/qiansuantidai-b.png");
}
.S4_li_T5{
    background-image: url("../img4/Cp_App/diandongche-h.png");
}
.S3_box_bot ul li:hover .S4_li_T5{
    background-image: url("../img4/Cp_App/diandongche-b.png");
}
.S4_li_T6{
    background-image: url("../img4/Cp_App/chunengxitong-h.png");
}
.S3_box_bot ul li:hover .S4_li_T6{
    background-image: url("../img4/Cp_App/chunengxitong-b.png");
}