/*App6_XW——XW_activity_01.js*/
.XW_activity_01_banner{
    height: 600px;
    position: relative;
}
.XW_activity_01_banner .Banner_XW_title{
    padding: 0;
    width: auto;
    height: auto;
    position: absolute;
    top: 60%;
    left: 20%;
}
.XW_activity_01_banner .Banner_XW_title::after{
    content: '';
    width: 8rem;
    height: 4px;
    background: rgba(220,55,48,1);
    position: absolute;
    bottom: -1.5rem;
    left: 0;
}

.XW_activity_box{
    padding: 40px 0 120px;
}
.XW_activity_box>div{
    width: 83%;
    margin: 0 auto ;
    padding-bottom: 60px;
    background:rgba(255,255,255,1);
}
.XW_activity_box>div h1{
    text-align: center;
    padding-top: 120px;
    font-size:2.5rem;
    font-weight:400;
    color:rgba(220,55,48,1);
}
.XW_activity_box>div>p{
    text-align: center;
    padding: 30px 0 40px;
    font-size:18px;
    font-weight:400;
    color:rgba(102,102,102,1);
}

.XW_activity_content{
    width: 80%;
    margin: 0 auto;
    padding: 50px 0;
    border-top: 1px solid rgba(153,153,153,0.3);
    border-bottom: 1px solid rgba(153,153,153,0.3);
}

.XW_activity_content>img{
    margin: 2rem auto;
    display: block;
    width: 85%;

}

.XW_activity_content>p{
    text-indent: 2rem;
    text-align: left;
    font-size:1.1rem;
    font-weight:400;
    color:rgba(102,102,102,1);
    line-height:36px;
}
.XW_activity_list{
    width: 80%;
    margin: 60px auto 0;
    display: flex;

    justify-content: space-between;
}
.XW_activity_list p{
    width: 20%;
    font-size:18px;
    font-weight:400;
    color:rgba(102,102,102,1);
}

.XW_activity_list p a{
    color:#999999;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
}
.XW_activity_list_return{
    width: 140px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    border:1px solid rgba(153,153,153,1);
}
.XW_activity_list_return a{
    display: inline-block;
    width: 100%;
    height: 100%;
}
.XW_activity_list p span,.XW_activity_list_return a{
    color: #666666!important;
    line-height: 40px;
}
.XW_activity_list_return:hover {
    background: red;
}
.XW_activity_list_return:hover a{
    color: #fff!important;
}


