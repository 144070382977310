#QianS .S4_Li_Curtain>div{
    text-align: left;
    width: 50%;
    transform: translate(0%,0%);
    top: 38%;
}

#QianS .S4_Li_Curtain>div h3{
    font-size:3.75rem;
    font-weight:bold;
    color:rgba(255,255,255,1);
}

#QianS .S4_Li_Curtain>div p{
    font-size:2.25rem;
    font-weight:400;
    color:rgba(255,255,255,1);
}

#QianS .Cp_Select_modular_left>div{
    color:rgba(255,255,255,1);
}
#QianS .Cp_Select_modular_left>div ul li::after{
    background:rgba(255,255,255,1);
}

