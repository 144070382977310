#foot .footer{
    width:100%;
    border-top:10px solid rgba(187,40,34,1);
    padding-top:20px;
}
#foot .footer-top{
    width:80%;
    margin:0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /*border:1px dashed red;*/
    /*border-bottom: 1px solid #E2E2E2;*/
    /*padding: 15px;*/
}
#foot .footer-logo{
    /*margin-left:5%;*/
    /*border:1px dashed red;*/
    display:inline-block;
    vertical-align:middle;
}
#foot .footer-logo img{
    width:100%;
    height:100%;
}
#foot .footer-desc{
    /*border:1px dashed red;*/
    display:inline-block;
    vertical-align:middle;
    /*margin-left:40%;*/
}
#foot .footer-desc p{
    color:#CD1B12;
    font-size: 1.1rem;
}
#foot .footer-bottom{
    width:100%;
    padding-top: 3.5rem;
}
#foot .footer-bottom ul{
    width:20%;
    /*border:1px dashed red;*/
}


#foot .footer-nav{
    width:80%;
    position: relative;
    margin:0 auto;
    height:10rem;

    display: flex;
    justify-content: space-between;
}

#foot .footer-nav::after{
    content: '';
    position: absolute;
    bottom: -15%;
    left: 0%;
    width: 100%;
    height:1px;
    background:rgba(102,102,102,1);
    opacity:0.2;
}

#foot .footer-nav .col-links{
    width:14%;
    /*border:1px solid cyan;*/
    float:left;
    /*margin-top:3%;*/
}
#foot .footer-nav .col-links:nth-child(1){
    /*margin-left: 2rem;*/
}

#foot .footer-nav .col-links li{
    margin-left: 10%;
    /*height: 1.5rem;*/
    text-align: left;
}
#foot .footer-nav .col-links li:hover a{
    color:#333333;
}
#foot .footer-nav .col-links li:nth-child(1) a{
    color:#333333;
    font-size:1rem;

}
#foot .footer-nav .col-links li a{
    color:#666666;
    font-size:0.9rem;
    line-height: 0.9rem;
}
#foot .footer-nav .col-links li:nth-child(1){
    margin-bottom:0.6rem;
    margin-left: 10%;
}

#foot .footer-nav ul:nth-last-child(1){
    width:12%;

}

#foot .footer-address{
    display:inline-block;
    color:#666666;
    float: left;
    text-align: left;
}
#foot .footer-address>p{
    font-size:1.05rem;
    color:#666666;
}
.footer-hr{
    margin-left: -5rem;
    width: 1px;
    background-color: rgba(102,102,102,0.2);
}
#foot .footer-address p:nth-child(1){
    font-weight:400;
    color:rgba(102,102,102,1);
    line-height:1.1rem;
    /*border:1px solid red;*/
}
#foot .footer-address p:nth-child(1)>span{
    display: inline-block;
    width: 3rem;
}
#foot .footer-address p:nth-child(2){

    font-weight:400;
    color:rgba(102,102,102,1);
    line-height:1.1rem;
    text-indent: 3rem;
    margin-top: 10px;
    /*padding-left:2.5rem;*/
}
#foot .footer-address p:nth-child(3){

    font-weight:400;
    color:rgba(102,102,102,1);
    line-height:1.1rem;
    margin-top:10px;

}
#foot .footer-address p:nth-child(4){
    width: 13rem;
    height: 1rem;

    font-weight: 400;
    color: rgba(102,102,102,1);
    line-height: 1.1rem;
    margin-top: 10px;
}
#foot .footer-address p:nth-child(5){
    margin-top: 10px;

}
#foot .footer-line{
    width:80%;
    margin:0 auto;
    border:1px solid #E2E2E2;

    position: relative;
    top: 20px;
}
#foot .footer_number{
    position: relative;
    font-size:0.75rem;
    margin-top: 2.5rem;
    padding:1.5rem;
}
.yyzz{
    position: absolute;
    right: 10%;
    top: 50%;
    transform: translateY(-60%);
}
.footer_menu{
    overflow: hidden;
    width: 70%;
    float: left;
}
@media (max-width:1270px) {
    #foot .footer-nav .col-links:nth-child(1){
        margin: 0;
    }
    .footer_menu{
        overflow: hidden;
        width: 60%;
        display: inline-block;
    }
    #foot .footer-nav .col-links,#foot .footer-nav ul:nth-last-child(1){
        width: 14%;
    }
    #foot .footer-address{
        float: none;
    }
    #foot .footer-nav ul:nth-last-child(1){
        border-right: 0px;
    }
}
