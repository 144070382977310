#MoZu .Cp_top_banner div{
    width: 50%;
    top: 60%;
    left:70%;
    text-align: left;
}
#MoZu .Cp_top_banner h3{
    font-size:3.75rem;
    font-weight:bold;
    color:rgba(220,55,48,1);
}
#MoZu .Cp_top_banner p{
    font-size:2.25rem;
    font-weight:400;
    color:rgba(74,76,84,1);
}

.MoZu_li{
    width: 80% ;
    margin: 0 auto;
    padding: 2rem 0 4rem;
}
.MoZu_li ul{
    width: 100%;
    display: flex;
    justify-content:space-between;
    overflow: hidden;
}
.MoZu_li li{
    width: 15.5%;
    float: left;
    padding: 3rem 2rem;
    text-align: center;
    background:rgba(255,255,255,1);
}
.MoZu_li li p{
    margin-top: 1rem;
    font-size:1rem;
    font-weight:400;
    color:rgba(102,102,102,1);
}