#Mod3{
    width: 100%;
    height: 100%;
    background:rgba(238,241,247,1);
}
.Mod3_bot{
    /*background: url("../img2/bg4.png") no-repeat center center;*/
    background-size: cover;
}

.Mod3_content{
    width: 80%;
    height: 100%;
    margin: 0 auto;
}
.Mod3_div .row{
    display: flex;
    justify-content: space-between;
}
.Mod3_div .row>div{
    width: 33%;
}
.Mod3_content h3{
    height: 40%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: Center;
    font-size:3.75rem;
    font-weight:bold;
    color:rgba(118,118,118,1);
    line-height:60px;
}
.Mod3_col{
    padding: 1rem;
}
.Mod3_div .row>div:first-child{
    padding: 0;
}
.Mod3_col div,.Mod3_col div img,.Mod3_col p{
    width: 100%;
}
.Mod3_col>div{
    overflow: hidden;
}
.Mod3_col>div img{
    transition: all 1s;
}
.Mod3_col>div:hover img{
    transform: scale(1.2);
}
.Mod3_col p{
    font-size:1.2rem;
    text-align: left;
    padding-top: 1rem;
    font-weight:400;
    color:rgba(118,118,118,1);
}