/*APP2——FirsScreen*/
#FS {
    width: 100%;
    height: 100%;
    color: rgba(254,254,254,1);
    /*background: url("../img2/FSbanner.png") no-repeat center center;*/
    background-size: cover;
    position: relative;
}

#FS .sou{
    color: white;
    display: block;
    position: absolute;
    top: 3rem;
    right: 3.75rem;
    z-index: 100;
}
/*#FS .serch{
    padding-top: 1.3rem;
    padding-right: 1.3rem;
    position: relative;
    top: 0.25rem;
    background: url("../images/img/serch.png") no-repeat center center;
    display: inline-block;
    width: 1rem;
    height: 1rem;
    background-size: contain;
}*/
#FS .serch:focus + .form input{
    animation: maxWidth 1s forwards;
}

#FS .form{
    display: inline-block;
    border-bottom: 1px solid rgba(255,255,255,1);
}

#FS .form input{
    font-size: 1rem;
    color: white;
    border: 0;
    outline:none;
    text-indent:0.5rem;
    background: rgba(248, 244, 255, 0);
    width: 0px;
}
#FS .serch:focus + .form input{
    animation: maxWidth 1s forwards;
}
#FS .form input:focus{
    width: 10rem
}
#FS .form input::-webkit-input-placeholder{
    color: white;
}
#FS  .mouses{
    position: absolute;
    bottom: 3.5rem;
    left:50vw;
    z-index: 101;
    animation: mouset 0.5s linear infinite alternate;
}

#FS  .mouses a{
    display: block;
    width: 2rem;
    height: 2rem;
    background: url("../images/img2/mouse-icon.png") no-repeat center center;
}
#FS .FS_title{
    position: absolute;
    width: 80%;
    text-align: center;
    bottom: 20%;
    left: 10%;
}
#FS .FS_title h3{
    font-size: 3.1rem;
    position: relative;
}
#FS .FS_title h3::after{
    content: '';
    position: absolute;
    bottom: -50%;
    left: 50%;
    transform: translateX(-50%);
    width: 5rem;
    height: 5px;
    background: red;
}
#FS .FS_title p{
    font-size: 2.5rem;
    margin-top: 3rem;
}