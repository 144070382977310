* { margin:0; padding:0;}
li{list-style: none}
.App1{
    width: 100%;
    height: 100%;
}
a:hover {
    text-decoration: none;
    color: #fff;
}

body{color:#222;-webkit-text-size-adjust:none;}
/*body,h1,h2,h3,h4,h5,h6,hr,p,blockquote,dl, dt,dd,ul,ol,li,pre,form,fieldset,legend,button,input,textarea,th,td,iframe{margin:0; padding:0;}*/

ol,ul{list-style:none;}

html,body{  overflow:hidden;}
.section-wrap{ width:100%;height:100%;overflow:visible;transition:transform 0.8s cubic-bezier(0.86,0,0.03,1);-webkit-transition:-webkit-transform 0.8s cubic-bezier(0.86,0,0.03,1);}
.section-wrap .section{ position:relative; width:100%; height:100%; background-position:center center; background-repeat:no-repeat;}
/*.section-wrap .section-1{ background-color:#000000}
.section-wrap .section-2{ background-color:rgba(102,102,102,1)}
.section-wrap .section-3{ background-color:#5bc0de}
.section-wrap .section-4{ background-color:#f0ad4e}
.section-wrap .section-5{ background-color:#d9534f}*/
.put-section-0{ transform:translateY(0);-webkit-transform:translateY(0);}
.put-section-1{ transform:translateY(-100%);-webkit-transform:translateY(-100%);}
.put-section-2{ transform:translateY(-200%);-webkit-transform:translateY(-200%);}
.put-section-3{ transform:translateY(-300%);-webkit-transform:translateY(-300%);}
.put-section-4{ transform:translateY(-400%);-webkit-transform:translateY(-400%);}
.App1 .put-section-5 {
    transform: translateY(-438%);
    -webkit-transform: translateY(-438%);
}
.section-btn{ width:14px;position:fixed;right:4%;top:50%;display: none}
.section-btn li{ width:0.8rem;height:1rem;cursor:pointer;text-indent:-9999px;border-radius:50%;-webkit-border-radius:50%;margin-bottom:12px;background:  rgba(255,255,255,1);text-align:center; color:#fff; onsor:pointer;}
.section-btn li.on{height:24px;border-radius:0.5rem; background:rgba(208,18,27,1);}
.arrow{ opacity:1;animation:arrow 3s cubic-bezier(0.5,0,0.1,1) infinite;-webkit-animation:arrow 3s cubic-bezier(0.5,0,0.1,1) infinite;transform:rotate(-90deg);-webkit-transform:rotate(-90deg); position:absolute;bottom:10px;left:50%;margin-left:-30px;width:60px;height:60px;border-radius:100%;-webkit-border-radius:100%;line-height:60px;text-align:center;font-size:20px;color:#fff;border:1px solid #fff;cursor:pointer;overflow:hidden;}
.arrow:hover{ animation-play-state:paused;-webkit-animation-play-state:paused;}
@keyframes arrow{ 0%,100%{bottom:10px; opacity:1;} 50%{bottom:50px; opacity:.5} }
@-webkit-keyframes arrow{  0%,100%{bottom:10px; opacity:1;} 50%{bottom:50px; opacity:.5} }


@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@media (min-width: 1200px){
    #zx .container {
        max-width: 80%;
    }

}
@media (max-width: 800px){
    html,body{
        font-size: 12px;
        overflow:visible!important;
    }
}

