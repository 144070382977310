#ChuNengXT .S4_Li_Curtain>div{
    text-align: right;
    width: 50%;
    left: 35%;
    top: 35%;
    background: linear-gradient(to right,rgba(0,0,0,0.0),rgba(0,0,0,0.25));

    padding: 3.5rem 2.5rem;
    transform: translate(0%,0%);
}

#ChuNengXT .S4_Li_Curtain>div h3{
    font-size:3rem;
    font-weight:bold;
    color:rgba(255,255,255,1);
    padding: 0 0 2rem;
}

#ChuNengXT .S4_Li_Curtain>div p{
    font-size:1.5rem;
    font-weight:400;
    color:rgba(255,255,255,1);
}

#ChuNengXT .Cp_Select_modular_left>div{
    color:rgba(255,255,255,1);
}
#ChuNengXT .Cp_Select_modular_left>div ul li::after{
    background:rgba(255,255,255,1);
}