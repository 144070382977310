/*App7 -  Contact_Recruit*/
.Contact_Recruit{
    width: 100%;
    background:rgba(238,241,247,1);
}
.Contact_Recruit_box{
    width: 84%;
    margin: 0 auto;
    padding: 6.25rem 0;
}
.Recruit_box_top{
    overflow: hidden;
    position: relative;
    border-bottom: 1px solid rgba(153,153,153,0.3);
    margin-bottom: 2.5rem;
}
.Contact_Recruit_box h1{
    padding: 2.8rem 0;
    line-height: 2.5rem;
    float: left;
    font-size:2.5rem;
    font-weight:400;
    color:rgba(102,102,102,1);
}
.Recruit_box_top_form{
    position: absolute;
    right: 0;
    bottom: 0;
}
.Recruit_box_top_form a{
    display: inline-block;
    width: 15px;
    height: 15px;
    position: absolute;
    right: 15px;
    top: 15px;
    background: url("../../images/img/serch1.png") no-repeat center center;
}
.Recruit_box_top_form input{
    margin-bottom: 2rem;
    width: 18.75rem;
    height: 40px;
    padding: 20px 14px;
    display: inline-block;
    color:rgba(153,153,153,1);
    border:1px solid rgba(153,153,153,1);
    border-radius:4px;
    background: rgba(153,153,153,0);
}
.Recruit_box_top_form p{
    text-align: center;
}
.Recruit_box_top_form p span{
    padding: 15px 13px;
    display: inline-block;
    line-height:1rem;
    font-size:1rem;
    font-weight:400;
    color:rgba(153,153,153,1);
}

/*下拉列*/
.Recruit_content_div_list{
    overflow: hidden;
}
.Recruit_content_div_list>li{
    width: 100%;
    margin-bottom: 30px;
}
.Recruit_content_div_list>li>ul{
    width: 100%;
}
.Recruit_content_div_list .content_div_li{
    overflow: hidden;
    background:rgba(255,255,255,1);
    transition: all 0.3s;
}
.Recruit_content_div_list .content_div_li:hover{
    box-shadow: rgba(153,153,153,0.5) -0px 20px 10px -1rem
    /*background: url("../../images/img7_Contact/Yinyin.png") no-repeat center center;*/
}
.Recruit_content_div_list>li>ul li{
    width: 20%;
    text-align: center;
    display: inline-block;
    background:rgba(255,255,255,1);
}
.Recruit_content_div_list>li>div{
    width: 85%;
    margin: 0px auto;
    transition: all 1s;
}

.Recruit_content_div_list>li .Recruit_content_center{
    padding: 30px 0 30px 2rem;
    border-bottom: 1px solid rgba(153,153,153,0.2);
    border-top: 1px solid rgba(153,153,153,0.2);

    line-height: 2rem;
    font-size:18px;
    font-weight:400;
    color:rgba(102,102,102,1);
}
.Recruit_content_center p{
    height: 2rem;
}
.Recruit_content_div_list>li{

}

.Recruit_content_div_list>li>ul li p{
    padding: 18px 0;
    line-height:18px;
    font-size:18px;
    font-weight:400;
    color:rgba(102,102,102,1);
}
.Recruit_content_div_list>li>ul li:last-child p{
    padding-left: 0.4rem;
    position: relative;
}
.Recruit_content_div_list>li>ul li:last-child a{
    width: 20px;
    height: 20px;
    position: absolute;
    top: 18px;
    right: 10%;
    transform: rotate(0deg);
    transition: all 1s;
    /*background: url("../../images/img7_Contact/zhankai-1.png") no-repeat center center;*/
}
.Recruit_content_div_list>li>ul li:first-child,
.Recruit_content_div_list>li>ul li:last-child{
    width: 29.75%;
    text-align: left;
    padding-left: 6%;

}
/*标题*/
.Recruit_content_title{

}
.Recruit_content_title li{
    margin-right: 1px;
    margin-bottom: 10px;
}
.Recruit_content_title h3{
    padding: 30px 0;
    line-height:24px;
    font-size:24px;
    font-weight:400;
    color:rgba(102,102,102,1);
}
/*content_bot*/
.Recruit_content_bot>ul{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Recruit_content_bot>ul>li{
    display: inline-block;
    padding: 1.6rem 0;
}
.Recruit_content_bot>ul>li div,.Recruit_content_bot>ul>li img{
    display: inline-block;
}
.Recruit_content_bot>ul>li img{
    margin-top: -1.5rem;
}
.Recruit_content_bot>ul>li>div p {
    padding-left: 1rem;
    font-size:1rem;
    font-weight:400;
    color:rgba(102,102,102,1);
    line-height:2rem;
}
