#nav{
    position: fixed;
    top: 0;
    left: 0;
    width: 37%;
    height: 100%;
    transition: all 0.5s;
    margin-left: -100%;
    background:rgba(187,40,34,0.8);
    z-index: 99999;
}
#nav .logo{
    position: fixed;
    top: 10px;
    left: 0;
    width: 190px;

    display: none;
    text-align: center;
    background: rgba(255,255,255,1);

    box-shadow: 0 4px 6px 0px rgba(0,0,0,0.05);

    transition: all 2s;
}
#nav .l_click{
    display: flex;
    cursor: pointer;
}
#nav .logo .l_img{
    eight: 62px;
    width: 128px;
    display: flex;
    align-items: center;
    justify-content: center;
}
#nav .l_img{
    display: inline-block
}
#nav .l_span{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    width: 62px;
    height: 62px;
    padding: 18px;

}
#nav .l_span span{
    width: 30px;
    display: block;
    height: 2px;
    margin-top: 10px;
    position: relative;
    left: 0rem;
    background: rgba(208,18,27,1);
    transition: all 0.4s ease-in-out;
}
#nav .l_span span:first-child{
    margin: 0;
}
#nav .l_span:hover span:nth-child(n){
    left: 0.3rem;
}
#nav .l_span:hover span:nth-child(2){
    left: -0.3rem;
}
#nav .logo .l_img{
    padding: 0.6rem 0.5rem;
    background:rgba(208,18,27,1);
    display: flex;
    align-items: center;
}
#nav .logo .l_img img{
    width: 108px;
    height: 30px;
}


#nav .nav_content{
    width: 100%;
    height: 100%;
    opacity:0.9;
    margin-left: -100%;
    transition: all 1s;
    background: url("../images/img/tu an.png") no-repeat bottom left;
    background-size: contain;
}

#nav .nav_content .list{
    position: absolute;
    top: 30%;
    width: 100%;
}
#nav .nav_content .list_img{
    width: 30%;
    float: left;
    margin-top: -3.2rem;
    text-align: center;
}
#nav .nav_content .list_img img{
    padding: 1rem 0;
}
#nav .nav_content .list_img div:nth-child(1) img{
    border-bottom: 2px solid white;
}
#nav .nav_content .list_dl{
    width: 60%;
    float: left;
    /*margin-top: 0.3rem;*/
    color: rgba(255,254,254,1);
}
#nav .nav_content .list_dl dt{
    line-height: 2.4rem;
    border-bottom: 1px solid rgba(255,255,255,1);
}
#nav .nav_content .list_dl dt a{

    font-size: 1.5rem;
    /*margin-left: 3rem;*/
    color: rgba(255,254,254,1);
    opacity:0.9;
    font-weight:400;
    display: inline-block;
    position: relative;
}
#nav .nav_content li,
#nav .nav_content li>a{
    text-transform: Capitalize ;
}
#nav .nav_content .list_dl dt a span{
    position: absolute;
    display: none;
    top: 1.25rem;
    left: -3rem;
    height: 1px;
    width: 2.5rem;
    background: rgba(255,255,255,1);
}



#nav .nav_content .list_dl dd{
    font-size:1.5rem;
    height: 3rem;
    position: relative;
    font-weight:400;
    color:rgba(255,255,255,1);
    overflow: hidden;
    transition: all 0.5s ease-in-out ;
}

#nav .nav_content .list_dl dd:hover{
    height: 16rem;
}
#nav .nav_content .list_dl dd:nth-of-type(3):hover{
    height: 21rem;
}
#nav .nav_content .list_dl dd ul{
    /*margin-left: 0rem;*/
    padding-left: 0rem;
    font-size: 1.1rem;
    /*cursor: pointer;*/
    margin-top: 0.5rem;
    transition: all 1.5s;
}
#nav .nav_content .list_dl dd ul li:nth-child(1) a{
    font-size:1.5rem;
    font-weight:400;
    color:rgba(255,255,255,1);
    position: relative;
}
#nav .nav_content .list_dl dd ul li{
    font-size:1.8rem;
    font-weight:400;
    color:rgba(255,255,255,1);
    line-height:2rem;
    transition: all 0.5s;
    position: relative;
}

#nav .nav_content .list_dl dd ul li a{
    font-size:1.1rem;
    font-weight:400;
    color:rgba(255,255,255,1);
    transition: all 0.4s;
    position: relative;
}

#nav .list_dl dd span{
    content: '';
    position: absolute;
    top: 1rem;
    left: -3rem;
    height: 1px;
    width: 2.5rem;
    background: white;
    transition: all 500ms;
}

#nav .nav_content .list_dl dd ul li:nth-of-type(n+2) a::after{
    content: '';
    position: absolute;
    top: 0.8rem;
    left: -3rem;
    height: 1px;
    width: 0rem;
    background: white;
    transition: all 200ms;
}
#nav .nav_content .list_dl dd ul:hover li{
    /*margin-left: 3rem;*/
    padding-left: 3rem;
}
#nav .nav_content .list_dl dd ul li:hover a{
    font-size:1.5rem;
    font-weight:400;
    color:rgba(255,255,255,1);
    line-height:2.5rem;
}
#nav .nav_content .list_dl dd ul li:hover a::after{
    top: 1rem;
    width: 2.5rem;
}


