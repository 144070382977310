.S1_box{
    width: 80%;
    height: 100%;
    margin: 0 auto;
    padding-top: 2rem;
}
.S1_bot{
    padding-top: 3rem;
}
.S1_bot ul{
    margin-top: 1rem;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
}
.S1_bot ul li{
    width: 24%;
    float: left;
}
.S1_li_div ,.S1_li_div_title{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    /* height: 55%; */
    /* padding: 2rem 0; */
    flex-wrap: wrap;
    align-items: flex-end;
}
.S1_li_div_content{
    margin-top: 1rem;
    background:rgba(255,255,255,1);
}
.S1_li_div_title img,.S1_li_div_title p{
    display: inline-block;

}
.S1_li_div_title p{
    font-weight:bold;
    font-size: 1.5rem;
    padding: 0 1rem 0;
    /*line-height: 4rem;*/
}
.S1_li_div_img,.S1_li_div_img img{
    width: 100%;
    transition: all 1s;
}
.S1_li_div_img{
    overflow: hidden;
}
.S1_li_div_content:hover .S1_li_div_img img{
    transform: scale(1.2);
}
.S1_li_div_content>p{
    min-height: 8rem;
    font-size:1.1rem;
    font-weight:400;
    color:rgba(137,137,137,1);
    padding: 1.5rem ;
}

